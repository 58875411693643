<template>
  <div class="share-image">
    <img src="./assets/myfree.bio.jpg" alt="" width="1" height="1" />
  </div>
  <header>
    <h1>Free Biography Generator</h1>
  </header>
  <main>
    <Slide>
      <div
        class="slide-item"
        v-for="(demo, index) in demoList"
        :key="index"
        @click="setPresetValue(index)"
      >
        <div class="slide-item__content">
          {{ demo }}
        </div>
      </div>
    </Slide>
    <div class="textarea-area">
      <textarea
        class="textarea"
        v-model="profiles"
        name="ss"
        id=""
        cols="30"
        rows="10"
        ref="textarea"
      ></textarea>
    </div>

    <div class="switcher">
      <label for="iconSwitcher"
        ><input id="iconSwitcher" type="checkbox" @change="toggleIcons" />
        <span class="switch-icon"></span> Toggle Icons</label
      >
    </div>
    <transition name="ease">
      <div class="icon-list" v-if="showIcons">
        <span
          class="icon-item"
          v-for="(icon, index) in iconList"
          :key="index"
          @click="insertSymbol(icon)"
          >{{ icon }}</span
        >
      </div>
    </transition>
    <div class="button-area">
      <div class="button-item">
        <button
          type="button"
          class="button primary"
          :data-clipboard-text="profiles"
          @click="doCopy"
        >
          Copy to clipboard
        </button>
      </div>
      <div class="button-item">
        <button type="reset" class="button" v-on:click.stop.prevent="reset">
          Reset
        </button>
      </div>
    </div>
  </main>
  <footer>
    <a target="_blank" href="https://beian.miit.gov.cn"
      >粤ICP备2021004032号-1
    </a>
  </footer>
</template>

<script>
import Slide from "./components/slide.vue";
import insertTextAtCursor from "insert-text-at-cursor";
import Clipboard from "clipboard";
import { useToast, POSITION } from "vue-toastification";

export default {
  name: "App",
  components: {
    Slide,
  },
  data() {
    return {
      showIcons: false,
      demoList: [
        `❤️ 🇨🇳 \n\n云淡风轻，和光同尘`,
        `📕 UI/UX Designer\n🙋‍♀️ 166cm/45kg\n\n📍 HongKong`,
        `Writer/Editor/Programmer/Designer\n Apple fans\n⚡️ Google fans\n🏝️ ocean fans\n\n📍 Tokyo`,
        ` Auburn 🏀 🏈 Duke 🏀 National Parks 🏞️ \n\n“Life's most persistent and urgent question is, 'What are you doing for others?'” - MLK. \n\nhe/him`,
        `🚀 Driver\n🔗 https://myfree.bio\n
\n🤙 123456789`,
        `A lawyer in Houston\nBorn in Austin\n\nNo pain no gain.`,
        `🧑‍💻 Programer @ SF\n Apple Fans\n🙅‍♂️ Bloger\n\n🛵 🚀 Driver\n\n🎏 Hiring programers\n\n🔗 :https://myfree.bio`,
        `撸起袖子加油干。\n❤️ SZ`,
      ],
      profiles: "",
      iconList: [
        "•",
        "*",
        "",
        "🍎",
        "📌",
        "💥",
        "⚡️",
        "🔥",
        "🚀",
        "📍",
        "🏠",
        "📅",
        "🔗",
        "🤙",
        "💫",
        "✨",
        "🌟",
        "⭐",
        "☝️",
        "✌",
        "❤️️",
        "💔",
        "💘",
        "🏳️‍🌈",
        "🌈",
        "🎮",
        "💎",
        "🌏",
        "🙋‍♂️",
        "🙋‍♀️",
        "🙅‍♂️",
        "🙅",
        "💁",
        "💁‍♀️",
        "🧑‍💻",
        "👩‍💻",
        "⚽️",
        "🏀",
        "🎾",
        "🏈",
        "🏉",
        "🎸",
        "🎖",
        "🥇",
        "🥈",
        "🥉",
        "🚗",
        "🚙",
        "🛵",
        "⛩",
        "🎏",
        "🎉",
        "🎎",
        "🎊",
        "🪅",
        "📩",
        "📕",
        "💮",
        "❌",
        "🚫",
        "💯",
        "🔞",
        "🔷",
        "🔺",
        "🔻",
        "◾️",
        "✅",
      ],
    };
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.initProfile();
  },
  methods: {
    initProfile() {
      this.profiles = this.demoList[0];
    },
    toggleIcons() {
      this.showIcons = !this.showIcons;
    },
    setPresetValue(index) {
      const value = this.demoList[index];
      this.setValue(value);
    },
    setValue(value) {
      if (JSON.stringify(value).length == 0) {
        this.profiles = "";
      } else {
        this.profiles = `${value}`;
      }
    },
    // clear content
    reset() {
      this.profiles = "";
    },
    // insert symbol
    insertSymbol(icon) {
      const textToInsert = `${icon} `;
      insertTextAtCursor(this.$refs.textarea, textToInsert);
    },
    doCopy: function() {
      let clipboard = new Clipboard(".button.primary");
      clipboard.on("success", () => {
        // 释放内存
        console.log("success");
        this.toast.success("success", {
          timeout: 1000,
          position: POSITION.TOP_CENTER,
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.toast.success("fail, try again");
        // 释放内存
        console.log(e);
        clipboard.destroy();
      });
      return;
    },
  },
};
</script>

<style>
html,
body {
  background-color: #f1efe2;
  margin: 0;
  padding: 0;
  font-size: 18px;
  height: 100%;
}
#app {
  font-family: "Arial Rounded MT Bold", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3e3e3e;
  background-color: #f1efe2;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
main {
  flex: 1;
}
.textarea-area {
  display: flex;
  padding: 10px 20px;
  box-sizing: border-box;
}
.textarea {
  flex: 1;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 10px;
  font-size: 18px;
  font-family: "Arial Rounded MT Bold";
}
.textarea:focus {
  outline: none;
}

.icon-list {
  display: flex;
  padding: 0 20px 10px;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: auto;
  font-family: serif;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.icon-list.hide {
  height: 0;
}
.icon-item {
  width: 44px;
  height: 44px;
  margin: 0 5px 5px 0;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  flex: 1 44px;
}
.button-area {
  margin-top: 10px;
  margin-bottom: 40px;
}
.button-item {
  margin-bottom: 1em;
}
.button {
  width: 100%;
  max-width: 300px;
  height: 44px;
  line-height: 44px;
  outline: 0;
  border: 0;
  background-color: #fff;
  border-radius: 22px;
  font-family: "Arial Rounded MT Bold";
  font-size: 1.2em;
}
.button.primary {
  background-color: #3eb770;
  color: #fff;
}
.slide-item {
  width: 246px;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  height: 152px;
}
.slide-item__content {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  position: relative;
  white-space: pre-wrap;
  font-size: 0.6em;
  overflow: hidden;
  box-sizing: border-box;
}
.is-selected .slide-item__content {
  border: 1px solid #3eb770;
}

.switcher {
  text-align: right;
  padding-right: 20px;
  padding-bottom: 10px;
  -webkit-tap-highlight-color: transparent;
}
.switcher input {
  display: none;
}

.switch-icon {
  display: inline-block;
  height: 20px;
  width: 40px;
  border-radius: 20px;
  background-color: #ddd;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  vertical-align: middle;
}
.switch-icon::after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switcher input[type="checkbox"]:checked + .switch-icon {
  background-color: #3eb770;
}
.switcher input[type="checkbox"]:checked + .switch-icon::after {
  left: 22px;
}
.share-image {
  position: absolute;
  width: 10px;
  height: 10px;
  overflow: hidden;
  left: -100px;
  top: -100px;
}
footer {
  padding: 1em 0;
  font-size: 0.8rem;
}
footer > a {
  color: #4b76af;
  text-decoration: none;
}
</style>
