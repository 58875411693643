import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here
};
app.use(Toast, options);
app.mount('#app');